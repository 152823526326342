import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  /**
   * Saves a value to local storage and session storage as a backup.
   *
   * @param key - The key under which the value is stored.
   * @param value - The value to be stored. This can be of any type.
   */
  setItem(key: string, value: any): void {
    try {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
      sessionStorage.setItem(key, jsonValue); // Backup in session storage
    } catch (e) {
      console.error(`Error saving to localStorage: ${e}`);
    }
  }

  /**
   * Retrieves a value from local storage. If the value is not found in local storage,
   * it attempts to retrieve it from session storage.
   *
   * @param key - The key of the item to retrieve.
   * @returns The retrieved value parsed as type T, or null if not found.
   */
  getItem<T>(key: string): T | null {
    try {
      const jsonValue = localStorage.getItem(key);
      if (jsonValue) {
        return JSON.parse(jsonValue) as T;
      } else {
        // Try to recover from session storage if local storage is empty
        const sessionJsonValue = sessionStorage.getItem(key);
        return sessionJsonValue ? (JSON.parse(sessionJsonValue) as T) : null;
      }
    } catch (e) {
      console.error(`Error getting data from localStorage: ${e}`);
      return null;
    }
  }

  /**
   * Removes a value from local storage and session storage.
   *
   * @param key - The key of the item to remove.
   */
  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
      sessionStorage.removeItem(key);
    } catch (e) {
      console.error(`Error removing from localStorage: ${e}`);
    }
  }

  /**
   * Clears all items from local storage and session storage.
   */
  clear(): void {
    try {
      localStorage.clear();
      sessionStorage.clear();
    } catch (e) {
      console.error(`Error clearing localStorage: ${e}`);
    }
  }
}
